.wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.wrapper.hasAddonAfter {
  display: grid;
  grid-template-columns: 1fr 40px;
}

.select {
  overflow: hidden !important;
  width: 100%;
}

.select div:first-child {
  border-right: none;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.suffix {
  align-items: center;
  background: rgba(0, 0, 0, 0.02);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #d9d9d9;
  border-left: none;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 0 11px;
}

.error {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5714285714285714;
  margin-top: -17px;
}
