.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools {
  align-items: center;
  display: flex;
}

.tools a {
  text-decoration: none;
}

.tools button{
  margin-left: 10px;
}

.viewUserAppToolBtn {
  align-items: center;
  display: flex;
}

.viewUserAppToolBtn svg {
  margin-right: 10px;
}

.viewUserAppToolBtn:hover svg path {
  stroke: #665fe8;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
