.wrapper {
  font-size: 14px;
}

.crumb {
  color: #7B809A;
  text-decoration: none;
}

.currentCrumb {
  color: #344767;
}

.separator {
  margin: 0px 5px;
}
